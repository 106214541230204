import type { IColorPalette, IThemeColors } from '@lemonenergy/lemonpie-themes'
import styled, { css } from 'styled-components'

export interface SectionProps {
  divider?: boolean
  variant?: `${keyof IThemeColors}.${keyof IColorPalette}`
  noPadding?: boolean
  paddingOnBottom?: boolean
}

const Section = styled.section<SectionProps>(
  ({
    divider,
    variant = 'neutral.lightest',
    noPadding = false,
    paddingOnBottom = false,
    theme: { borderStyle, borderWidth, colors, media, spacing },
  }) => {
    const [color, tone] = variant.split('.') as [
      color: keyof IThemeColors,
      tone: keyof IColorPalette,
    ]

    let fontColor = colors.neutral.darkest
    if (
      color === 'primary' ||
      color === 'secondary' ||
      (color === 'neutral' && tone === 'darkest')
    )
      fontColor = colors.neutral.lightest

    return css`
      align-items: center;
      background-color: ${colors[color][tone]};
      color: ${fontColor};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: ${noPadding ? '0' : spacing(9)} 0;
      width: 100%;
      ${paddingOnBottom &&
      css`
        padding-bottom: ${spacing(9)};
      `}

      ${divider &&
      css`
        border-bottom: ${borderWidth.main} ${borderStyle.solid}
          ${colors.neutral.light};
      `}

      ${media.sm} {
        padding: ${noPadding ? '0' : spacing(16)} 0;
        ${paddingOnBottom &&
        css`
          padding-bottom: ${spacing(9)};
        `}
      }
    `
  },
)

export default Section
